




import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        GetAuthToken: () =>
            import('@/modules/auth/components/GetAuthToken.vue'),
    },
})
export default class AuthCallbackView extends Vue {}
